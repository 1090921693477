import {isWithoutRegistration} from '@wix/wix-events-commons-statics'
import {isMobile} from '../../../../../../commons/selectors/environment'
import {
  getButtonBorderRadius,
  getButtonHeight,
  getOneButtonWidth,
  getRoundedButtonBorderRadius,
  isHollowButton,
  isResponsive,
  isRoundedButton,
} from '../../../../../../commons/selectors/settings'
import {hasEventDetailsUrl} from '../../../selectors/events'
import {
  getComponentConfig,
  getRsvpButtonMargin,
  isOnlyButtonVisible,
  isOnImageLayout,
  isRsvpLabelVisible,
} from '../../../selectors/settings'
import {AppProps} from '../../app/interfaces'
import {withMargin} from '../../hoc/with-margin'
import {connect} from '../../runtime-context/connect'
import {RsvpButton as RsvpButtonPresentation} from './rsvp-button'
import {RsvpButtonOwnProps, RsvpButtonStateProps} from './interfaces'

const mapState = ({state, actions}: AppProps, {event}: RsvpButtonOwnProps): RsvpButtonStateProps => {
  const componentConfig = getComponentConfig(state)

  return {
    rsvpLabelVisible: isRsvpLabelVisible(state),
    roundedButton: isRoundedButton(componentConfig),
    hollowButton: isHollowButton(componentConfig),
    width: isMobile(state) ? undefined : getOneButtonWidth(componentConfig),
    height: isMobile(state) ? undefined : getButtonHeight(componentConfig),
    borderRadius: getButtonBorderRadius(componentConfig),
    roundedBorderRadius: getRoundedButtonBorderRadius(componentConfig),
    onImage: isOnImageLayout(state),
    isMobile: isMobile(state),
    responsiveButton: isResponsive(componentConfig) && isOnlyButtonVisible(state),
    hidden: isWithoutRegistration(event),
    hasUrl: hasEventDetailsUrl(state, event),
    navigateToPage: () => actions.navigateToPage(event),
    settings: componentConfig.settings,
  }
}

const getMargin = ({state}: AppProps) => getRsvpButtonMargin(state)

export const RsvpButton = withMargin(
  connect<RsvpButtonOwnProps, RsvpButtonStateProps>(mapState)(RsvpButtonPresentation),
  getMargin,
)
export * from './interfaces'
