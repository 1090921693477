import {getEventDateInformation} from '../../../../../../../commons/selectors/event'
import {getHorizontalDividerHeight, getHorizontalDividerWidth} from '../../../../../../../commons/selectors/settings'
import {
  getComponentConfig,
  getDateMargin,
  getLocationMargin,
  isDateVisible,
  isHorizontalDividersVisible,
  isLabelsVisible,
  isLocationVisible,
  isOnImageLayout,
} from '../../../../selectors/settings'
import {AppProps} from '../../../app/interfaces'
import {renderIfVisible} from '../../../hoc/render-if-visible'
import {withMargin} from '../../../hoc/with-margin'
import {connect} from '../../../runtime-context/connect'
import {Presentation} from './presentation'

interface PresentationOwnProps {
  event: wix.events.Event
  dataHook?: string
}

interface PresentationStateProps {
  text: string
  text2?: string
  titleVisible: boolean
  dividerVisible: boolean
  dividerWidth: number
  dividerHeight: number
  onImage: boolean
  settings: WidgetComponentSettings
  settingsKey: string
}

export interface PresentationProps extends PresentationOwnProps, PresentationStateProps {}

const mapLocationState = ({state}, ownProps: PresentationOwnProps): PresentationStateProps => {
  const componentConfig = getComponentConfig(state)

  return {
    text: ownProps.event.location.name,
    text2: ownProps.event.location.address,
    titleVisible: isLabelsVisible(state),
    dividerVisible: isHorizontalDividersVisible(state),
    dividerWidth: getHorizontalDividerWidth(componentConfig),
    dividerHeight: getHorizontalDividerHeight(componentConfig),
    onImage: isOnImageLayout(state),
    settings: componentConfig.settings,
    settingsKey: 'location',
  }
}

const mapDateState = ({state}, ownProps: PresentationOwnProps): PresentationStateProps => {
  const componentConfig = getComponentConfig(state)

  return {
    text: getEventDateInformation(state, ownProps.event.id).fullDate,
    titleVisible: isLabelsVisible(state),
    dividerVisible: isHorizontalDividersVisible(state),
    dividerWidth: getHorizontalDividerWidth(componentConfig),
    dividerHeight: getHorizontalDividerHeight(componentConfig),
    onImage: isOnImageLayout(state),
    settings: componentConfig.settings,
    settingsKey: 'time',
  }
}

const locationMargin = ({state}: AppProps) => getLocationMargin(state)

export const Location = renderIfVisible(
  withMargin(connect<PresentationOwnProps, PresentationStateProps>(mapLocationState)(Presentation), locationMargin),
  isLocationVisible,
)

const dateMargin = ({state}: AppProps) => getDateMargin(state)

export const Date = renderIfVisible(
  withMargin(connect<PresentationOwnProps, PresentationStateProps>(mapDateState)(Presentation), dateMargin),
  isDateVisible,
)
